<template>
  <v-container fluid fill-height>
    <v-layout align-center justify-center>
      <v-flex xs12 sm8 md5>
        <ValidationObserver v-slot="{handleSubmit}">
          <m-card :title="$t('account.changePassword')">
            <v-form class="mb-5">
              <c-input
                class="my-3"
                dir="ltr"
                v-model="model.oldPassword"
                prepend-icon="mdi-lock"
                :label="$t('shared.oldPassword')"
                :rules="'required|min:6'"
                type="password"
              />

              <c-input
                ref="password"
                class="my-3"
                dir="ltr"
                v-model="model.newPassword"
                prepend-icon="mdi-lock"
                :label="$t('shared.newPassword')"
                :rules="'required|min:6'"
                type="password"
              />

              <c-input
                v-model="model.confirmPassword"
                prepend-icon="mdi-lock"
                class="my-3"
                dir="ltr"
                :label="$t('shared.confirmPassword')"
                :rules="`required|min:6|confirmed:${$t('shared.newPassword')}`"
                type="password"
              />
            </v-form>

            <div class="d-flex">
              <v-btn color="success" @click="handleSubmit(changePassword)" :loading="progressing">
                {{ $t('account.changePassword') }}
              </v-btn>

              <v-spacer></v-spacer>
            </div>
          </m-card>
        </ValidationObserver>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
export default {
  name: 'ChangePassword',
  data() {
    return {
      progressing: false,
      model: {
        oldPassword: null,
        newPassword: null,
        confirmPassword: null
      }
    }
  },
  methods: {
    changePassword() {
      return this.$api.account
        .changePassword(this.model)
        .then((res) => {
          this.$showSuccess(this.$t('account.passwordChanged'))
          this.model.oldPassword = null
          this.model.newPassword = null
          this.model.confirmPassword = null
          this.$validator.reset()
        })
        .catch((err) => {
          this.$showError(err)
        })
        .finally(() => {
          this.progressing = false
        })
    }
  }
}
</script>
